import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  InputNumber,
  message,
  PageHeader,
  Row,
  Select,
} from "antd";
import CarnavalService from "../api/backend/services/carnaval.service";
import CarnavalType from "../types/carnaval.type";
import {
  ADMIN_URL_APP_CARNAVAL,
  ADMIN_URL_APP_CARNAVAL_DETAILS,
} from "../routes/routes";
import CategoryItemType from "../types/category-item.type";
import NoteService from "../api/backend/services/note.service";
import JudgeType from "../types/judge.type";
import SchoolItemType from "../types/school-item.type";
import NoteType from "../types/note.type";
import toast from "react-hot-toast";

const AscertainmentPage = () => {
  const [carnival, setCarnival] = useState<CarnavalType>();
  const [winner, setWinner] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const load = useCallback(
    async (silent = false) => {
      setLoading(!silent);

      if (!id) throw new Error("id is required");
      const result = await CarnavalService.findByCode(id);
      if ("message" in result.body) toast.error(result.body.message);
      else setCarnival(result.body);

      setLoading(false);
    },
    [id]
  );

  const handleChangeValue = async (
    value: string,
    judge: JudgeType,
    schoolItem: SchoolItemType,
    categoryItem: CategoryItemType
  ) => {
    setCarnival((currentCarnival) => {
      if (!currentCarnival) return currentCarnival;

      const notes = currentCarnival.notes || [];

      const updatedNotes = notes.map((note) => {
        if (
          note.judge === judge._id &&
          note.school === schoolItem.school._id &&
          note.category === categoryItem.category._id
        ) {
          return { ...note, value };
        }
        return note;
      });

      return { ...currentCarnival, notes: updatedNotes };
    });
  };

  async function updateNote(
    code: string,
    value: string,
    judge: JudgeType,
    schoolItem: SchoolItemType,
    categoryItem: CategoryItemType
  ) {
    handleChangeValue(value, judge, schoolItem, categoryItem);

    if (!carnival) return;

    let result;

    const note: NoteType = {
      id: code,
      judge: judge._id,
      category: categoryItem.category._id,
      event: carnival._id!,
      school: schoolItem.school._id,
      value,
    };

    try {
      if (code !== "0") result = await NoteService.update(note);
      else result = await NoteService.add(note);
    } catch (error) {
      message.error("Erro ao atualizar a nota");
    }

    if ("message" in result.body) toast.error(result.body.message);
    else {
      toast.success("Nota atualizada com sucesso", { position: "top-right" });
      await load(true);
    }
  }

  async function updateFinishedEvent() {
    if (!carnival) return;
    const winnerSelect = winner === "Selecione a escola campeã" ? "" : winner;
    await CarnavalService.finishedEvent(
      carnival._id,
      !carnival.finished,
      winnerSelect
    );
    load().then();
  }

  function getNoteByJudgeAndSchool(
    judgeId: string,
    schoolId: string,
    categoryId: string
  ) {
    if (!carnival || !carnival.notes) return;

    // eslint-disable-next-line consistent-return
    return carnival.notes.find(
      (note) =>
        note.judge === judgeId &&
        note.school === schoolId &&
        note.category === categoryId
    );
  }

  useEffect(() => {
    load().then();
  }, [load]);

  function renderButtonFinished() {
    if (!carnival) return;

    // eslint-disable-next-line consistent-return
    return (
      <Button
        type="primary"
        size="middle"
        htmlType="button"
        onClick={() => updateFinishedEvent()}
      >
        {carnival.finished ? "Iniciar Apuração" : "Finalizar Apuração"}
      </Button>
    );
  }

  function renderSelectWinner() {
    if (!carnival || !carnival?.penalties) return;
    // eslint-disable-next-line consistent-return
    return (
      <Form layout="inline">
        <Form.Item
          label="Escola Campeã"
          tooltip="Seleciona e escola campeã em caso de empate, caso contrario deixe esse campo vazio."
        >
          <Select
            defaultValue={carnival?.winner || "Selecione a escola campeã"}
            disabled={loading}
            onChange={(value) => setWinner(value)}
          >
            <Select.Option
              key="not_defined_1"
              value="Selecione a escola campeã"
            >
              Selecione a escola campeã
            </Select.Option>
            {carnival.penalties.map((schoolItem) => (
              <Select.Option
                key={schoolItem._id}
                value={schoolItem.school.name}
              >
                {schoolItem.school.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    );
  }

  function renderBreadCrumb() {
    if (!carnival?._id) return;

    // eslint-disable-next-line consistent-return
    return (
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={ADMIN_URL_APP_CARNAVAL}>Carnavais</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link
            to={ADMIN_URL_APP_CARNAVAL_DETAILS.replace(":id", carnival?._id)}
          >
            Detalhes
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Apuração</Breadcrumb.Item>
      </Breadcrumb>
    );
  }

  function renderExtra() {
    return (
      <>
        {renderSelectWinner()}
        {renderButtonFinished()}
      </>
    );
  }

  function renderTable(categoryItem: CategoryItemType) {
    if (!carnival?._id || !carnival.penalties) return;

    // eslint-disable-next-line consistent-return
    return (
      <Card
        title={categoryItem.category.name}
        loading={loading}
        style={{ marginBottom: 24 }}
        key={categoryItem._id}
      >
        <Row gutter={[16, 16]}>
          <Col span={4}>
            <h1>Escolas</h1>
          </Col>
          {categoryItem.judges.map((judge, index) => (
            <Col key={index} span={4}>
              {judge.name}
            </Col>
          ))}
        </Row>
        {carnival?.penalties
          ?.sort((a, b) => {
            const dateA = a?.createdAt ? new Date(a.createdAt).getTime() : 0;
            const dateB = b?.createdAt ? new Date(b.createdAt).getTime() : 0;
            return dateA - dateB;
          })
          .map((schoolItem) => (
            <Row gutter={[16, 16]} key={schoolItem._id}>
              <Col span={4}>{schoolItem.school.name}</Col>
              {categoryItem.judges.map((judge) => (
                <Col span={4} key={judge._id}>
                  <InputNumber
                    step={0.1}
                    min="0"
                    max="10"
                    value={
                      getNoteByJudgeAndSchool(
                        judge._id,
                        schoolItem.school._id,
                        categoryItem.category._id
                      )?.value || 0
                    }
                    onBlur={(event) => {
                      updateNote(
                        getNoteByJudgeAndSchool(
                          judge._id,
                          schoolItem.school._id,
                          categoryItem.category._id
                        )?._id || "0",
                        event.target.value?.toString() || "0",
                        judge,
                        schoolItem,
                        categoryItem
                      );
                    }}
                    disabled={carnival?.finished}
                    style={{ width: "100%" }}
                  />
                </Col>
              ))}
            </Row>
          ))}
      </Card>
    );
  }

  return (
    <div style={{ padding: 32 }}>
      {carnival && (
        <PageHeader
          title={`Apuração do ${carnival?.name} - ${carnival?.city} ${carnival?.year}`}
          extra={renderExtra()}
          breadcrumbRender={renderBreadCrumb}
        />
      )}
      {carnival?.categoryItem?.map((categoryItem) => renderTable(categoryItem))}
    </div>
  );
};

export default AscertainmentPage;
